import { EnumLiteralsOf } from './helperTypes';

export const YesorNo = Object.freeze({
  YES: 'Yes' as const,
  NO: 'No' as const,
});
export type POSStores = EnumLiteralsOf<typeof POSStores>;

export const POSStores = Object.freeze({
  DEFAULT: 'DEFAULT' as const,
  PRODUCT: 'PRODUCT' as const,
  CATEGORY: 'CATEGORY' as const,
  USERMANAGE: 'USERMANAGE' as const,
});

export const Privileges = Object.freeze({
  Dashboard: 'Dashboard' as const,
  DashboardAdminZone: 'Dashboard_Admin_Zone' as const,
  DashboardTrendingProduct: 'Dashboard_Trending_Product' as const,
  DashboardTodaySaleZone: 'Dashboard_Today_Sale_Zone' as const,
  DashboardProductAlert: 'Dashboard_Product_Alert' as const,
  DashboardCategoryZone: 'Dashboard_Category_Zone' as const,
  DashboardWeeklySaleZone: 'Dashboard_Weekly_Sale_Zone' as const,
  DashboardLoyality: 'Dashboard_Loyality' as const,

  CategoryEdit: 'Category_Edit' as const,
  ProductDDelete: 'Product_Delete' as const,
  Sales: 'Sales' as const,
  CategoryView: 'Category_View' as const,
  ProductAAdd: 'Product_Add' as const,
  ProductEdit: 'Product_Edit' as const,
  ProductView: 'Product_View' as const,
  CategoryDelete: 'Category_Delete' as const,
  Report: 'Report' as const,
  CategoryAdd: 'Category_Add' as const,
  Inventory: 'Inventory' as const,
  Purchase: 'Purchase' as const,
  BillPayments: 'BillPayments' as const,
  UserManageEdit: 'UserManage_Edit' as const,
  UserManageDelete: 'UserManage_Delete' as const,
  UserManageAdd: 'UserManage_Add' as const,
  UserManageView: 'UserManage_View' as const,
  SupplierManageEdit: 'SupplierManage_Edit' as const,
  SupplierManageDelete: 'SupplierManage_Delete' as const,
  SupplierManageAdd: 'SupplierManage_Add' as const,
  SupplierManageView: 'SupplierManage_View' as const,
  CompanyManageEdit: 'CompanyManage_Edit' as const,
  CompanyManageDelete: 'CompanyManage_Delete' as const,
  CompanyManageAdd: 'CompanyManage_Add' as const,
  CompanyManageView: 'CompanyManage_View' as const,
  BranchManageEdit: 'BranchManage_Edit' as const,
  BranchManageDelete: 'BranchManage_Delete' as const,
  BranchManageAdd: 'BranchManage_Add' as const,
  BranchManageView: 'BranchManage_View' as const,
  CustomerManageEdit: 'CustomerManage_Edit' as const,
  CustomerManageDelete: 'CustomerManage_Delete' as const,
  CustomerManageBalance: 'CustomerManage_Balance' as const,
  CustomerManageBalanceHistory: 'CustomerManage_History' as const,
  CreditView: 'Credit_View' as const,
  CreditPayment: 'Credit_Payment' as const,

  CustomerManageAdd: 'CustomerManage_Add' as const,
  CustomerManageView: 'CustomerManage_View' as const,
  SalesReturn: 'SalesReturn' as const,
  SalesList: 'Sales_List' as const,
  PurchaseList: 'Purchase_List' as const,
  PatientView: 'Patient_View' as const,
  PatientEdit: 'Patient_Edit' as const,
  PatientAdd: 'Patient_Add' as const,
  dentalTreatmentView: 'Dental_Treatment_View' as const,
  Reports: 'Reports' as const,
  ReportDailySummary: 'Report_Daily_Summary' as const,
  ReportMonthlySummary: 'Report_Monthly_Summary' as const,
});

export const TreatmentType = Object.freeze({
  TeethCleanings: 'Teeth Cleanings' as const,
  TeethWhitening: 'Teeth Whitening' as const,
  Extractions: 'Extractions' as const,
  Veneers: 'Veneers' as const,
  Fillings: 'Fillings' as const,
  Crowns: 'Crowns' as const,
  RootCanal: 'Root Canal' as const,
  BracesInvisalign: 'Braces/Invisalign' as const,
  Bonding: 'Bonding' as const,
  Dentures: 'Dentures' as const,
});
