import React, { useMemo, useState } from 'react';
import {  Divider,  Drawer,  List,  ListItem,  ListItemButton,  ListItemIcon,  ListItemText,  styled,  useMediaQuery,} from '@mui/material';
import GroupIcon from '@mui/icons-material/Groups';
import CategoryIcon from '@mui/icons-material/Category';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useNavigate } from 'react-router-dom';
import useApp from '../../utils/useApp';
import { appColors } from '../../theme/appColors';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Privileges } from '../../core/types/commonType';
import useUser from '../../core/hooks/useUser';
import Inventory from '@mui/icons-material/Inventory';
import SellIcon from '@mui/icons-material/Sell';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import PaymentIcon from '@mui/icons-material/Payment';
import { IUserInfo } from '../../core/Interface/api.interface';
//import Typography from '@mui/material/Typography';
import { useGetUserQuery } from '../../page/posApi';
import { theme } from '../../theme';
import DehazeIcon from '@mui/icons-material/Dehaze';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import StoreIcon from '@mui/icons-material/Store';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import AssignmentReturnOutlinedIcon from '@mui/icons-material/AssignmentReturnOutlined';
import AppsIcon from '@mui/icons-material/Apps';
import ClassIcon from '@mui/icons-material/Class';
import ListIcon from '@mui/icons-material/List';
import MedicationLiquidIcon from '@mui/icons-material/MedicationLiquid';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import MedicationIcon from '@mui/icons-material/Medication';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AssessmentIcon from '@mui/icons-material/Assessment';

const drawerWidth = 240;

type SubmenuItem = {
  title: string;
  path: string;
  privilege: string;
  newPage?: boolean;
  icon: React.ReactElement;
  open?: boolean;
};

type MenuItem = {
  title: string;
  icon: React.ReactElement;
  path?: string;
  newPage?: boolean;
  privilege?: string;
  submenuOpen?: boolean;
  submenus?: SubmenuItem[];
};

const DrawerHeader = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const menuList: MenuItem[] = [
  {
    title: 'Dashboard',
    icon: <DashboardIcon />,
    path: '/dashboard',
    privilege: Privileges.Dashboard,
  },
  {
    title: 'Catalog',
    icon: <ClassIcon />,
    submenuOpen: true,
    submenus: [
      {
        title: 'Category',
        icon: <CategoryIcon />,
        path: '/category',
        privilege: Privileges.CategoryView,
      },
      {
        title: 'Product',
        icon: <ProductionQuantityLimitsIcon />,
        path: '/product',
        privilege: Privileges.ProductView,
      },
      {
        title: 'Inventory',
        icon: <InventoryOutlinedIcon />,
        path: '/inventory-details',
        privilege: Privileges.Inventory,
      },
    ],
  },
  {
    title: 'Sales',
    icon: <PointOfSaleIcon />,
    submenuOpen: true,
    submenus: [
      {
        title: 'Sales',
        icon: <PointOfSaleIcon />,
        path: '/sales',
        privilege: Privileges.Sales,
      },
      {
        title: 'Sales List',
        icon: <FileCopyIcon />,
        path: '/sales-list',
        privilege: Privileges.SalesList,
      },
    ],
  },
  {
    title: 'Purchase',
    icon: <Inventory />,
    submenuOpen: true,
    submenus: [
      {
        title: 'Purchase',
        icon: <Inventory />,
        path: '/purchase',
        privilege: Privileges.Purchase,
      },
      {
        title: 'Purchase List',
        icon: <SellIcon />,
        path: '/purchase-list',
        privilege: Privileges.PurchaseList,
      },
    ],
  },
  {
    title: 'Return',
    icon: <AssignmentReturnOutlinedIcon />,
    submenuOpen: true,
    submenus: [
      {
        title: 'Return',
        icon: <AssignmentReturnOutlinedIcon />,
        path: '/return-sales',
        privilege: Privileges.SalesReturn,
      },
      {
        title: 'Return List',
        icon: <KeyboardReturnIcon />,
        path: '/return-list',
        privilege: Privileges.SalesReturn,
      },
    ],
  },

  {
    title: 'Others',
    icon: <AppsIcon />,
    submenuOpen: true,
    submenus: [
      {
        title: 'Customer',
        icon: <SupportAgentIcon />,
        path: '/customer-management',
        privilege: Privileges.CustomerManageView,
      },
      {
        title: 'Supplier',
        icon: <GroupIcon />,
        path: '/Supplier-management',
        privilege: Privileges.SupplierManageView,
      },
      {
        title: 'Company',
        icon: <AddBusinessIcon />,
        path: '/company-management',
        privilege: Privileges.CompanyManageView,
      },
      {
        title: 'Branch',
        icon: <StoreIcon />,
        path: '/branch-management',
        privilege: Privileges.BranchManageView,
      },
      {
        title: 'User',
        icon: <PeopleAltIcon />,
        path: '/user-management',
        privilege: Privileges.UserManageView,
      },
    ],
  },
  {
    title: 'Bill Payments',
    icon: <PaymentsOutlinedIcon />,
    submenuOpen: true,
    submenus: [
      {
        title: 'Payment',
        icon: <PaymentIcon />,
        path: '/billPayments',
        privilege: Privileges.BillPayments,
      },
      {
        title: 'History',
        icon: <ListIcon />,
        path: '/bill-history',
        privilege: Privileges.BillPayments,
      },
    ],
  },
  {
    title: 'Pharmacy',
    icon: <MedicationLiquidIcon />,
    submenuOpen: true,
    submenus: [
      {
        title: 'Treatments',
        icon: <AccessibilityNewIcon />,
        path: '/pharmacy/treatments',
        privilege: Privileges.PatientView,
      },
      {
        title: 'Patient',
        icon: <PeopleAltIcon />,
        path: '/pharmacy/patients',
        privilege: Privileges.PatientView,
      },
      {
        title: 'Dental',
        icon: <MedicationIcon />,
        path: '/pharmacy/dental',
        privilege: Privileges.dentalTreatmentView,
      },
    ],
  },
  {
    title: 'Reports',
    icon: <SummarizeIcon />,
    submenuOpen: true,
    submenus: [
      {
        title: 'Daily Summary',
        icon: <AssessmentIcon />,
        path: '/dailyReports',
        privilege: Privileges.ReportDailySummary,
      },
      // {
      //   title: 'Monthly  ',
      //   icon: <SummarizeIcon />,
      //   path: '/monthlyReports',
      //   privilege: Privileges.ReportDailySummary,
      // },
    ],
  },
];

const Menu = ({ ...rest }) => {
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  //const { data } = useGetUserQuery();
  // const companyName = useMemo(() => {
  //   if (data?.data) {
  //     const userData = data.data as IUserInfo;
  //     if (userData.company) {
  //       return userData.company.name;
  //     }
  //   }
  //   return false;
  // }, [data]);

  const navigate = useNavigate();
  const [open] = React.useState(true);
  const { currentMenuName } = useApp();
  const { userHasRight } = useUser();
  const [openDrawer, setDrawer] = useState(isSmall);
  // const [openSubmenus, setOpenSubmenus] = useState<string[]>([]);
  const [expandMenu, setExpandMenu] = useState<string>('');

  const handleMainMenuItemClick = (ml: MenuItem) => {
    if (isSmall) {
      setDrawer(!openDrawer);
    }

    if (ml.submenus && ml.submenus.length > 0) {
      // const updatedSubmenus = [...openSubmenus];
      // const submenuIndex = updatedSubmenus.indexOf(ml.title);
      // if (submenuIndex === -1) {
      //   updatedSubmenus.push(ml.title);
      // } else {
      //   updatedSubmenus.splice(submenuIndex, 1);
      // }
      // setOpenSubmenus(updatedSubmenus);
      ml.title === expandMenu ? setExpandMenu('') : setExpandMenu(ml.title);
    } else if (ml.path) {
      navigate(ml.path);
    }
  };

  return (
    <>
      {currentMenuName === '/sales' ||
      currentMenuName === '/purchase' ? null : (
        <Drawer
          anchor={openDrawer ? 'top' : 'left'}
          open={open}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
        >
          <DrawerHeader
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              boxShadow:
                '0px 3px 6px rgb(51 51 51 / 10%), 0px 1px 3px rgb(51 51 51 / 10%), 0px 2px 4px -1px rgb(51 51 51 / 5%)',
            }}
          >
            {isSmall && <DehazeIcon onClick={() => setDrawer(!openDrawer)} />}

            <img
              src={'/images/logo.png'}
              height={70}
              width={120}
            />
          </DrawerHeader>
          <Divider />
          {!openDrawer && (
            <>
              {' '}
              <List>
                {menuList.map((ml, index) => {
                  const shouldRenderMainMenuItem =
                    (!ml.privilege || userHasRight(ml.privilege)) &&
                    (!ml.submenus ||
                      ml.submenus.some(
                        (submenu) =>
                          !submenu.privilege || userHasRight(submenu.privilege)
                      ));

                  if (shouldRenderMainMenuItem) {
                    return (
                      <div key={ml.title}>
                        <ListItem
                          disablePadding
                          key={ml.title}
                        >
                          {/* Main menu item */}
                          <ListItemButton
                            onClick={() => handleMainMenuItemClick(ml)}
                          >
                            <ListItemIcon>
                              {ml.icon as React.ReactNode}
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                ml.privilege && userHasRight(ml.privilege)
                                  ? ml.title
                                  : ml.title
                              }
                            />
                            {ml.submenus &&
                              (expandMenu === ml.title ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              ))}
                          </ListItemButton>
                        </ListItem>

                        {/* Submenus */}
                        {ml.submenus && (
                          <Collapse
                            in={expandMenu === ml.title}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List>
                              {ml.submenus.map((submenu) => {
                                const hasSubMenuRight =
                                  !submenu.privilege ||
                                  userHasRight(submenu.privilege);

                                return (
                                  hasSubMenuRight && (
                                    <ListItem
                                      disablePadding
                                      key={submenu.title}
                                      sx={{ paddingLeft: theme.spacing(4) }}
                                    >
                                      <ListItemButton
                                        onClick={() => {
                                          if (isSmall) {
                                            setDrawer(!openDrawer);
                                          }
                                          if (submenu.newPage) {
                                            window.open(submenu.path, '_blank');
                                          } else {
                                            if (
                                              currentMenuName !== submenu.path
                                            )
                                              navigate(submenu.path);
                                          }
                                        }}
                                        sx={{
                                          background:
                                            currentMenuName === submenu.path
                                              ? appColors.darkGray[10]
                                              : '',
                                        }}
                                      >
                                        <ListItemIcon>
                                          {submenu.icon as React.ReactNode}
                                        </ListItemIcon>
                                        <ListItemText
                                          primary={
                                            hasSubMenuRight ? submenu.title : ''
                                          }
                                        />
                                      </ListItemButton>
                                    </ListItem>
                                  )
                                );
                              })}
                            </List>
                          </Collapse>
                        )}
                      </div>
                    );
                  }
                  return null;
                })}
              </List>
            </>
          )}
        </Drawer>
      )}
    </>
  );
};

export default React.memo(Menu);
